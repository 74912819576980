import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel } from "@coreui/react-pro";
import userServices from "../../services/user";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../contexts/AlertContext";

import logo from "../../assets/icon/logo_branca.png";
import insta from "../../assets/img/mediaButtons/insta.png"
import whats from "../../assets/img/mediaButtons/whats.png"
import linkedin from "../../assets/img/mediaButtons/linkedin.png"
import mais from "../../assets/img/mediaButtons/mais.webp"

function LoginBlog() {
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();

  const defaultUserData = {
    email: "",
    password: "",
  };

  const [user, setUser] = useState(defaultUserData);
  const navigate = useNavigate();

  async function login() {
    setIsLoading(true);

    const res = await userServices.login(user);

    if (!res.success) {
      showAlert(res.message, "error");
      setIsLoading(false);
      return;
    }

    navigate("/");
    setIsLoading(false);
  }

  return (
    <div className="login">
      <div className="loginNav">
        <a className="back" href="www.palangana.com.br">Voltar ao site</a>
        <img className="logo" src={logo} alt="Logo da palangana" />
        <div className="medias">
          <a href="/"><img src={insta} alt="" /></a>
          <a href="/"><img src={whats} alt="" /></a>
          <a href="/"><img src={linkedin} alt="" /></a>
        </div>
      </div>
      <div className="loginContent">
        <CForm>
          <h1 className="mb-3">Realizar login</h1>
          <p className="mb-4">
            Ao acessar nossos sistemas, saiba que sua atividade é rastreada
            e seus dados são protegidos por criptografia, conforme exigido
            pela Lei Geral de Proteção de Dados Pessoais (LGPD) para sua
            segurança.
          </p>
            <CFormLabel>E-mail:</CFormLabel>
            <CFormInput
              type="email"
              className="me-2"
              placeholder=""
              value={user.email}
              onChange={(e) =>
                setUser((prev) => ({ ...prev, email: e.target.value }))
              }
            ></CFormInput>
            <CFormLabel className="mt-2">Senha:</CFormLabel>
            <CFormInput
              type="password"
              className="me-2"
              placeholder=""
              value={user.password}
              onChange={(e) =>
                setUser((prev) => ({ ...prev, password: e.target.value }))
              }
            ></CFormInput>
          <button
            className="mt-4"
            type="button"
            disabled={isLoading}
            onClick={login}
          >
            <div className="liquid"></div>
            <span>{isLoading ? "Carregando.." : "Entrar"}</span>
          </button>
        </CForm>
      </div>
      <div className="loginFooter">
        <p>Palangana © 2024 | A Software developed by <a target="_blank" href="https://singlec.com.br/">Singlec</a></p>
      </div>
    </div>
  );
}

export default LoginBlog;
