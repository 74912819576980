import React, { useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCardImage
} from "@coreui/react-pro";
import { useAlert } from "../../contexts/AlertContext";
import postServices from "../../services/post";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomReactQuill from "../CustomReactQuill";

function UpdatePostModal({ visible, hideModal, post }) {

  const [selectedPost, setSelectedPost] = useState(post);
  const { showAlert, hideAlert } = useAlert()
  
  const maxSizeInBytes = 2 * 1024 * 1024 // 2 MB
  const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
  const text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod veniam laborum sunt, a assumenda recusandae tempora maxime, in, magnam asperiores et hic ut iure. Est cum reiciendis tenetur officia amet.'

  const handleFileChange = (e) => {

    const file = e.target.files[0]

    if (file) {
      hideAlert()

      if (allowedTypes.includes(file.type) && file.size <= maxSizeInBytes) {
        setSelectedPost(prev => ({ ...prev, image: { url: URL.createObjectURL(file), file } }))
      } else {
        showAlert('Por favor, selecione um arquivo PDF, PNG, JPEG ou JPG com até 2 MB.', 'error')
      }
    }
  };

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {

      hideAlert()

      if (selectedPost.image.file.type === 'text/html' ) {
        showAlert('Por favor, selecione um arquivo PDF, PNG, JPEG ou JPG com até 2 MB.', 'error')
        return
      }

      //update post database data
      const hadFileChange = "name" in selectedPost?.image.file

      const postResponse = await postServices.updatePost(selectedPost)

      //update post image
      let fileRes = { success: true }
      if (hadFileChange) {

        fileRes = await postServices.uploadPostImage({
          id_post: post.id,
          rawFile: selectedPost.image.file
        })
      }

      //show post response to user
      showAlert(postResponse.message, postResponse.success ? 'success' : 'error')

      //hide modal and refetch if some data changed
      if (postResponse.success) {
        hideModal()
        queryClient.invalidateQueries({
          queryKey: ['carrosel'],
        })
      }


      //show file error response to user
      if (!fileRes.success) {
        hideAlert()
        showAlert(fileRes.message, 'error')
      }

    },
    onError: (response) => {
      console.log(response)
      showAlert(response.message, 'error')
    }
  })


  return (
    <CModal
      backdrop={"static"}
      id="modal"
      size="xl"
      visible={visible}
      aria-labelledby="OptionalSizesExample1"
      onClose={hideModal}
    >
      <CModalHeader>
        <CModalTitle id="OptionalSizesExample1"></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="title-modal">Editar publicação</div>
        <CForm>
          <div className="mt-5 input-img">
            <CFormLabel>Adicionar imagem:</CFormLabel>
            {selectedPost.image?.file && (
              <>
                <div className="postImageContainer">
                  <CCardImage orientation="top" src={selectedPost.image?.url} />
                </div>
                <p>{selectedPost.image.file.name}</p>
              </>
            )}
            <CFormInput
              type="file"
              accept=".png,.jpeg,.jpg,.webp"
              maxLength="50"
              floatingClassName="mb-3"
              onChange={handleFileChange}
            />
          </div>
          <CFormLabel className="mt-4">Titulo:</CFormLabel>
          <CFormInput
            type="text"
            value={'Lorem Ipsum'}
            /* onChange={e => setSelectedPost(prev => ({ ...prev, title: e.target.value }))} */
          />
          <CFormLabel className="mt-4">Descrição:</CFormLabel>

          <CustomReactQuill
            value={text}
            /* onChange={e => setSelectedPost(prev => ({ ...prev, description: e }))} */
          />

          <CButton className="button-modal mt-5 mb-3" onClick={mutate} disabled={isPending}>
            {isPending ? 'Editando...' : 'Editar'}
          </CButton>
        </CForm>
      </CModalBody>
    </CModal>
  );
}

export default UpdatePostModal;
